import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { RootState, AppDispatch } from "../states/store"
import { setToggledNavButton } from "../states/app/appSlice"


type Props = {
    title: string,
    det: string
    linkTitle: string
    link: string
}
function NavInfoCard(props: Props) {
    const messageUsDialog = useSelector((state: RootState) => state.app.messageUsDialog);
    const dispatch = useDispatch<AppDispatch>();
    return (
        <div className="">
            <div className="text-4xl font-bold leading-10 text-black mb-11">
                {props.title}
            </div>
            <div className="pb-4 text-sm text-lg font-normal align-baseline text-slate-700">
                {props.det}
            </div>
            <div className="flex items-center justify-between text-sm font-bold text-center text-red-800 max-w-fit">
                <Link to={props.link} className="capitalize" onClick={() => dispatch(setToggledNavButton(''))}>{props.linkTitle.toUpperCase()}</Link>
                <svg className="w-5 h-5" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                </svg>
            </div>
        </div>
    )
}

export default NavInfoCard;