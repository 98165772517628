import * as React from "react";
import ServicesCard from "../ServicesCard";

function OurServices() {
  return (
    <div className="flex flex-col justify-center  md:w-full max-w-[1242px]">
      <div className="flex items-start self-center justify-between w-full gap-5 pb-8 mt-16 font-bold ">
        <div className="text-4xl leading-10 text-black">Our Services</div>

      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid lg:grid-cols-4 md:grid md:grid-cols-3 ">
        <ServicesCard imageSrc={"images/services/serv1.PNG"} description1={"Property"} description2={"Valuations"} lunk={"/property-evaluation"} />
        <ServicesCard imageSrc={"images/services/serv2.PNG"} description1={"Building & Facilities"} description2={"Consultancy"} lunk={"/building-and-facilities-consultancy"} />
        <ServicesCard imageSrc={"images/services/serv3.PNG"} description1={"Project Management & "} description2={"Cost Consultancy"} lunk={"/project-managment-and-cost-consultancy"} />
        <ServicesCard imageSrc={"images/services/serv4.webp"} description1={"Building Inspection and"} description2={"Snagging"} lunk={"/inspection-snagging"} />
        <ServicesCard imageSrc={"images/services/serv5.webp"} description1={"Strategic"} description2={"Consulting"} lunk={"/property-strategic-consultancy"} />
        <ServicesCard imageSrc={"images/services/serv6.PNG"} description1={"Sales And Leasing"} description2={"Brokage"} lunk={"/sales-and-leasing-brokerage"} />
        <ServicesCard imageSrc={"images/services/serv7.webp"} description1={"Property Investment"} description2={"Advisory"} lunk={"/property-investment-advisory"} />
        <ServicesCard imageSrc={"images/services/serv8.webp"} description1={"Property"} description2={"Managment"} lunk={"/property-management"} />
      </div>
    </div>
  );
}


export default OurServices;