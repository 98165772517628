import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function InvestMentAdvisory() {
    const services = [
        { title: "Investment Due Diligence" },
        { title: "Built-to-Suit Structuring" },
        { title: "Debt Advisory & Development Finance" },
        { title: "Sale & Leaseback" },
        { title: "Fundraising – Debt/Equity" },
        { title: "Sales & Acquisition", },
        { title: "Portfolio Assessment and Review", },
        { title: "Technical Due Diligence" },
        { title: "Financial Due Diligence" },
        { title: "Investment Optimization" },
        { title: "Real Estate Investment Trust (REITs) & Sukuk" },
        { title: "Assistance in Portfolio Diversification" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-bold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Investment', 'Advisory']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Expert Property Investment Consultancy in Ethiopia.
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                KOKETI is Ethiopia’s leading property investment consultancy services company with 14+ years of industry experience. Our investment advisory experts offer you in-depth and sector-specific analysis of plausible real estate investment opportunities.
                                            </p>
                                            <p>
                                                Being one of the best real estate investment advisory consultants in Ethiopia, KOKETI offers value-driven advisory services backed by market trend analysis and due diligence.
                                            </p>
                                            <p>
                                                If you’re exploring property investment opportunities in Ethiopia and aiming to make informed decisions, the advisory services of our property investment company are tailored for you. We continually monitor the evolving real estate regulations in Ethiopia, ensuring that we guide our clients with precision and expertise in their property investment endeavors.
                                            </p>
                                            <p>
                                                KOKETI has worked with SEDCO Capital, Al Rajhi Capital, Al Bilad Capital, Emirates REIT, ENBD REIT, GII REIT, and Al Mal Capital to source real estate acquisition opportunities. These assets include high-end hotels, grade-A office buildings, international schools, and theme parks.
                                            </p>

                                        </div>



                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@KOKETI.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Professional Real Estate Investment Consultants
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        Our property investment advisory team’s entire ethos revolves around optimizing asset performance for all stakeholders and therefore focuses on solution-based strategies that while they may not be high-income-producing in the short term, are the most sustainable, adding intrinsic value to both life and land and therefore actively contributing to the green economy.
                                    </p>


                                </div>

                                <div className="flex flex-col gap-6 bg-white mt-11 ">
                                    <div className="text-3xl font-bold">Our Services</div>
                                    <ul className="grid grid-cols-1 text-lg list-disc lg:grid-cols-2 list pl-11">
                                        <li className="">Real Estate Investment Trust (REITs) & Sukuk</li>
                                        <li className="">Portfolio Diversification</li>
                                        <li className="">Investment Due Diligence</li>
                                        <li className="">Built-to-Suit Structuring</li>
                                        <li className="list-disc">Debt Advisory & Development Finance</li>
                                        <li className="">Sale & Leaseback</li>

                                    </ul>

                                </div>
                                {/* <ServiceListSection /> */}
                                <div className="flex flex-col gap-10">
                                    <Title title={"Sector-specialised Property Valuation"} />
                                    <p>With extensive experience and an outstanding reputation
                                        amongst leading property valuation firms in Ethiopia, our
                                        team has expertise in a range of sectors.</p>
                                    <div className="hidden lg:block">
                                        <ServiceListSection />
                                    </div>

                                    <div className="flex flex-col items-center justify-center gap-7 lg:hidden">
                                        <div className="w-full px-5 py-8 rounded-lg shadow-lg">
                                            <div className="flex justify-center ">
                                                <div className="text-2xl font-bold text-center">Service</div>

                                            </div>
                                        </div>

                                        {
                                            services.map((service, index) => (
                                                <div className="w-full px-5 py-8 rounded-lg shadow-2xl">
                                                    <div className="flex justify-center">
                                                        <div>{service.title}</div>
                                                        <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                    <div className="font-sans text-4xl font-bold">
                                        Benefits of Property Investment Consultancy Services in Ethiopia
                                    </div>

                                    <div className="text-2xl font-bold">
                                        Expert Research & Analysis
                                    </div>
                                    <div className="text-lg">
                                        Our real estate investment consultants are adept at conducting research and analysis of the current real estate market trends. They offer you suggestions and recommendations regarding investment decisions based on an in-depth market analysis.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Connections in the Industry
                                    </div>
                                    <div className="text-lg">
                                        You need the right industry connections to ensure that the real estate investment decisions bring you the best value. KOKETI is deeply integrated into Ethiopia’s real estate market which enables us to help our clients get the results they want in record time.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Professional Negotiation
                                    </div>
                                    <div className="text-lg">
                                        Our real estate investment advisors negotiate on your behalf to ensure that the real estate investment decision stays profitable for you. They negotiate on your behalf so that you can score a great deal.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Location Expertise
                                    </div>
                                    <div className="text-lg">
                                        Our real estate investment consultants have location expertise covering European, Middle Eastern, and African countries. You can take advantage of our real estate investment experience in these areas to figure out the feasibility of your real estate investment for these places.

                                    </div>
                                    <div className="text-2xl font-bold">
                                        Legal Documentation
                                    </div>
                                    <div className="text-lg">
                                        Our property advisors in Ethiopia are adept at handling complicated legal documentation concerning property investments. We will draft the documents and process them with the concerned authorities on your behalf. We offer complete assistance in handling paperwork to facilitate your property investment endeavour.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Handling Post-Sale Concerns
                                    </div>
                                    <div className="text-lg">
                                        Our real estate investment advisors stay with you even after the investment has been made to help you navigate complications associated with the investment. Our experts offer assistance with any questions or queries that you might have post-sale.
                                    </div>
                                    ServiceListSection</div>

                            </div>
                        </div>
                    </div>


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default InvestMentAdvisory;