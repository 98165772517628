import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function SalesAndLeasingBrokage() {
    const services = [
        { title: "Structural & MEP Testing" },
        { title: "BOQ Services" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Property Snagging and Inspections" },
        { title: "Survey & Audits" },
        { title: "Building Defect Investigation", },
        { title: "Measured Surveys", },
        { title: "QA/QC Inspection and Audits" },
        { title: "Building Condition Surveys & Audits – Civil, MEP, Structural & Facade" },
        { title: "Witnessing of Testing & Commissioning" },
        { title: "Energy Audits" },
        { title: "Energy Audit ASHRAE Level 1 & 2" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Related Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Sales & Leasing', 'Brokerage']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col mb-14 gap-11 max-md:flex-col max-md:gap-0">
                                <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Best Sales & Leasing Brokerage Services in Ethiopia
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        KOKETI Properties is the independent brokerage arm of KOKETI. We provide a complete array of brokerage services from buying, selling, managing, and monitoring properties across the globe. We offer sales and leasing in Ethiopia to ensure a reliable sale and purchase of a real estate in Ethiopia. With the combined local knowledge and global expertise in real estate of KOKETI, we are revolutionising the way properties are bought and sold in MENA.
                                    </p>
                                    <p>
                                        From market evaluations to closing the deal, we are with you every step of the way to make each transaction go smoothly and easily, answering all your questions and taking care of every detail.
                                    </p>

                                </div>

                            </div>
                            <div className="flex flex-col flex-col-reverse lg:grid lg:grid-cols-2 gap-x-20">
                                <div className="">
                                    <img className="w-full h-full" src="images/services/sl1.jpg" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">KOKETI Properties</div>
                                    <div className="text-lg">
                                        With more than 100 years of cumulative experience in the UAE, Saudi, and UK Real Estate markets, KOKETI is an internationally acclaimed, award-winning firm. KOKETI Properties is a progressive real estate brokerage division based in Addis Ababa.

                                        We’ve built our success by keeping our clients at the heart of everything we do.
                                    </div>
                                </div>

                                <div className=" lg:hidden">
                                    <img className="w-full h-full" src="images/services/sl2.webp" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">We’ve built our success by keeping our clients at the heart of everything we do.</div>
                                    <div className="text-lg">
                                        We understand that clients are not just looking to purchase or rent a property, but a home to make memories in.  We instill passion and empathy into our real estate consultants so that they understand the importance of moving or investing from a client’s perspective.
                                    </div>
                                </div>
                                <div className="hidden lg:block">
                                    <img className="w-full h-full" src="images/services/sl2.webp" alt="" />
                                </div>


                                <div className="">
                                    <img className="w-full h-full" src="images/services/sl3.webp" alt="" />
                                </div>

                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">An expert team you can trust.</div>
                                    <div className="text-lg">
                                        We know that real estate decisions are difficult, and we are confident in our expertise to help you make the right property decisions. We are proud to say that our responsibility is to locate the right property and ensure the process is as smooth and enjoyable for every client.
                                    </div>
                                </div>
                                <div className=" lg:hidden">
                                    <img className="w-full h-full" src="images/services/sl4.jpg" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Why choose KOKETI?</div>
                                    <div className="flex flex-col gap-4 text-lg">
                                        <i>

                                            Our team is diligent, professional, diverse, and ready to cater to your every property need. We won’t rest until you are 100% satisfied with our service. We are trusted by our clients, accessible, and always within reach. Our real estate consultants are handpicked and trained to represent our brand and your interests professionally. We bring to the table unrivalled community expertise across the local markets.
                                        </i>

                                    </div>
                                </div>
                                <div className="hidden lg:block">
                                    <img className="w-full h-full" src="images/services/sl4.jpg" alt="" />
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Best Strategic Consulting Firm in Ethiopia
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                KOKETI is a leading strategic consulting firm in Ethiopia with 14+ years of industry experience. We offer expert strategic consulting services to developers, property, owners, and occupiers to maximize profit margins. Our strategic consulting firm specializes in strategy development for construction projects, real estate investments, and property inspections.
                                            </p>
                                            <p>
                                                Our strategic consulting firm specializes in strategy development for construction projects, real estate investments, real estate management consulting, and property inspections. We have a team of RICS-certified professionals and financial experts who handle the strategic aspects of property management for you to ensure success for every real estate project.
                                            </p>
                                            <p>
                                                Being closely integrated into Ethiopia's real estate market for over a decade, KOKETI has developed a knack for handling complicated strategy development and execution processes.
                                            </p>

                                        </div>
                                        <div className="flex flex-col gap-6 mt-11 ">
                                            <div className="text-3xl font-bold">Our Services Cover the Following Real Estate Areas</div>
                                            <ul className="grid grid-cols-1 text-lg list-disc list pl-11">
                                                <li className="">Industrial</li>
                                                <li className="">Retail</li>
                                                <li className="">Investments</li>
                                                <li className="">Residential</li>
                                                <li className="list-disc">Commercial</li>

                                            </ul>

                                        </div>
                                        <div className="flex flex-col gap-6 font-sans mt-11 even:mb-11">
                                            <div className="font-sans text-4xl font-bold">
                                                What Makes KOKETI Stand Out
                                            </div>

                                            <div className="text-2xl font-bold">
                                                14+ Years of Industry Experience
                                            </div>
                                            <div className="text-lg">
                                                <p>KOKETI has been a part of Ethiopia’s real estate market for 14+ years. Throughout our years of professional service, we have helped real estate investors land great deals for their investments.</p>
                                                <p>We also help businesses looking to lease a property for business operations. We conduct lease brokerage for our clients to ensure that they get the best deal for the lease.</p>
                                            </div>
                                            <div className="text-2xl font-bold">
                                                RICS-Certified Team
                                            </div>
                                            <div className="text-lg">
                                                <p>
                                                    We have a team of RERA-regulated professionals who are great at understanding sales and leasing requirements for our clients. They work with you through each step of the way while offering you expert recommendations regarding real estate sales and leasing.
                                                </p>
                                                <p>
                                                    Being a RERA-regulated firm, KOKETI doesn’t compromise on the quality of services. We have a reputation for being thorough in our approach which enables us to deliver an unmatched quality of services.
                                                </p>
                                            </div>
                                            <div className="text-2xl font-bold">
                                                10,000+ Real Estate Projects Completed
                                            </div>
                                            <div className="text-lg">
                                                <p>
                                                    Our confidence is backed by our practical real estate industry experience. We have completed 10,000+ real estate investment, development, and property management projects covering all the major industry sectors in Ethiopia. We specialise in the sales and leasing brokerage for commercial as well as residential real estate to ensure the best return on your investment.
                                                </p>
                                            </div>
                                            <div className="text-2xl font-bold">
                                                Collaborative & Communicative Team
                                            </div>
                                            <div className="text-lg">
                                                <p>
                                                    Our trained professionals keep you updated each step of the way throughout the project lifecycle. We believe in communication and transparency. We make sure to keep the communication channels open while we work on securing the right real estate sales or leasing deal for you.
                                                </p>
                                                <p>
                                                    We work with all parties involved on your behalf while conducting due diligence from our end to ensure that the deal you are getting is capable of meeting your long-term operational requirements.
                                                </p>
                                            </div>
                                            <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                                Real Estate Sales & Leasing Brokerage Experts in Ethiopia
                                            </div>
                                            <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                                <p>
                                                    KOKETI is a leading sales and leasing brokerage services company in Ethiopia with expertise in finding and brokering the right sales and leasing deals for investors. We research on your behalf to ensure that you get the right deal for your investment that is capable of bringing in long-term positive gains.
                                                </p>
                                                <p>
                                                    Contact KOKETI right now to get a free quote for our sales and leasing brokerage services in Ethiopia
                                                </p>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@KOKETI.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default SalesAndLeasingBrokage;