import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function SnaggingAndInspection() {
    const services = [
        { title: "Structural & MEP Testing" },
        { title: "BOQ Services" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Property Snagging and Inspections" },
        { title: "Survey & Audits" },
        { title: "Building Defect Investigation", },
        { title: "Measured Surveys", },
        { title: "QA/QC Inspection and Audits" },
        { title: "Building Condition Surveys & Audits – Civil, MEP, Structural & Facade" },
        { title: "Witnessing of Testing & Commissioning" },
        { title: "Energy Audits" },
        { title: "Energy Audit ASHRAE Level 1 & 2" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Related Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Building Inspections &', 'Snagging']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Building Inspections & Snagging Experts in Ethiopia and Regional States
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                A snag in building management is a minor or major technical or architectural defect found on a property. It includes everything from damages to the property to the technical issues such as improper fitting for essential home equipment.
                                            </p>
                                            <p>
                                                You should never compromise on a snag, even if it doesn’t seem like a big problem at the moment. In most cases, minor snags can lead to serious architectural issues in the future.
                                            </p>
                                            <p>
                                                We will also carry out reviews of maintenance contracts and records, drawings reviews, warranties reviews, Operation & Maintenance manuals review, and authority compliance review to advise on best practice for any rectifications required. These inspections can be tailored to each individual property and to each of our clients requirements. Structural Condition Assessments (SCA) are typically a more detailed and intrusive survey where we would engage this if we identify any potentially catastrophic failures when carrying out the BCA. As with all our inspections, the reporting procedures detail out any defects and advise on best practice rectification methods and high level costings and the reports are in line with applicable authority standards and are regulated by the Royal Institution of Chartered Surveyors (RICS).
                                            </p>

                                        </div>



                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@KOKETI.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Why Property Snagging is Necessary?
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        The world of property investment is often considered to be quite high-risk. You want to avoid any mistakes while buying or selling the property that might end up affecting the value of your home in the future.
                                    </p>
                                    <p>
                                        Whether you’re buying or selling a home in Ethiopia, conducting a thorough property inspection is essential. Such inspections can reveal both visible and hidden structural issues that may exist within the property. By identifying these concerns through a property inspection in Ethiopia, you gain valuable insights into the condition of the building. If you’re the current owner, you can address these defects directly. However, if you’re in the process of purchasing, having the homeowner rectify these issues before finalizing the transaction ensures the property retains its market value and integrity.
                                    </p>

                                </div>
                                <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    How Does a Snagging Service Work?
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        As one of the best inspection services companies in the UAE, our snagging process involves a comprehensive investigation of the property to identify any minor or major defects. Our snagging experts carefully inspect your home, uncovering both visible and hidden issues and document these defects in a detailed report.
                                    </p>
                                    <p>
                                        Once the inspection is completed, they provide a report that contains all the details regarding the snags they found on the property. After that, you can forward the report to the housebuilder to get the issues fixed before final documentation.
                                    </p>
                                    <p>
                                        Is the snagging company well-known in your area? Do they have a positive reputation in the local market? What about their track record and references? These are the questions that you need to find the answer to before choosing a property snagging company in Ethiopia.
                                    </p>

                                </div>
                                <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                    <div className="font-sans text-4xl font-bold">
                                        How to Find the Best Snagging Company in Ethiopia
                                    </div>

                                    <div className="text-2xl font-bold">
                                        Consider the Market Reputation
                                    </div>
                                    <div className="text-lg">
                                        Is the snagging company well-known in your area? Do they have a positive reputation in the local market? What about their track record and references? These are the questions that you need to find the answer to before choosing a property snagging company in Ethiopia.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Consider the Experience
                                    </div>
                                    <div className="text-lg">
                                        Consider the past projects that the company has worked on to judge the quality of its services. Most companies have an online portfolio that you can check out to see their previous snag inspection projects. This can help you gauge their level of expertise in a much better way.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Interview the Snagging Company
                                    </div>
                                    <div className="text-lg">
                                        Once you have selected a snagging company to hire for your project, you need to interview them to learn more about their process. Don’t be afraid to ask questions regarding any confusion that you might have about the snagging services.


                                    </div>
                                    <div className="text-2xl font-bold">
                                        Read Client Reviews
                                    </div>
                                    <div className="text-lg">
                                        You can also investigate the references that you find online on independent review websites for the snagging services provider that you are thinking about hiring for your project. Reading reviews can make it easier for you to decide about going for a snagging company.


                                    </div>
                                    <div className="text-lg">Get in touch with KOKETI right now to get an accurate property valuation for your commercial or residential property.</div>
                                </div>
                                <div className="flex flex-col max-w-full gap-10 bg-stone-50">
                                    <div className="hidden lg:block">
                                        <ServiceListSection />
                                    </div>

                                    <div className="flex flex-col items-center justify-center gap-7 lg:hidden">
                                        <div className="w-full px-5 py-8 rounded-lg shadow-lg">
                                            <div className="flex justify-center ">
                                                <div className="text-2xl font-bold text-center">Service</div>

                                            </div>
                                        </div>

                                        {
                                            services.map((service, index) => (
                                                <div className="w-full px-5 py-8 rounded-lg shadow-2xl">
                                                    <div className="flex justify-center">
                                                        <div>{service.title}</div>
                                                        <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Comprehensive Snagging Services & Building Inspection Services Provider
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        KOKETI has a number of different levels of building inspections. Building Condition Assessments (BCA), typically involve a visual, non-intrusive inspection of a property, inspecting all civil, structural, and MEP elements and assets of the property. We make use of Thermal Imaging Cameras (TIC), Moisture Testing Meters, Thermo-Hygrometers, Vibration Meters, Air Quality Meters, Inspection Cameras, Circuit and Socket testers, and Sound level meters, to determine the serviceability of the assets and to determine any defects within the property.
                                    </p>
                                    <p>
                                        We will also carry out reviews of maintenance contracts and records, drawings reviews, warranties reviews, Operation & Maintenance manuals reviews, and authority compliance reviews to advise on best practices for any rectifications required. These inspections can be tailored to each property and each of our clients requirements. Structural Condition Assessments (SCA) are typically a more detailed and intrusive survey where we will engage if we identify any potentially catastrophic failures when carrying out the BCA.
                                    </p>
                                    <p>
                                        As with all our inspections, the reporting procedures detail any defects and advise on best practice rectification methods and high-level costings and the reports are in line with applicable authority standards and are regulated by the Royal Institution of Chartered Surveyors (RICS).
                                    </p>

                                </div>
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    Expert Property Snagging Services in Ethiopia
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        With newly built units currently being handed over at record levels and with developers under massive pressure to complete and hand them over quickly, the level of defects that are identified is quite staggering.
                                    </p>
                                    <p>
                                        KOKETI is the region’s leading snagging and handover specialist and our dedicated team of engineers all have extensive knowledge and experience in the built environment and always provide the highest levels of quality, expertise, and support to our clients as well as giving developers insights and advice to help build and deliver better.
                                    </p>

                                </div>
                                <div className="flex flex-col gap-6 bg-white mt-11 ">
                                    <div className="text-3xl font-bold">Our Snagging Services Cover</div>
                                    <ul className="grid grid-cols-1 text-lg list-disc lg:grid-cols-2 list pl-11">
                                        <li className="">Foundation inspection</li>
                                        <li className="">Foundation inspection</li>
                                        <li className="">Walls and ceilings</li>
                                        <li className="">Floors</li>
                                        <li className="list-disc">Doors</li>
                                        <li className="">Carpets</li>
                                        <li className="">Kitchen structure and equipment</li>
                                        <li className="">Air Conditioners</li>
                                        <li className="">Water Supply/Drainage</li>
                                        <li className="">Electrical Sockets</li>
                                        <li className="">Water Pump System</li>
                                        <li className="">Water Tank </li>
                                        <li className="">Furniture</li>
                                        <li className="">Heating System</li>
                                        <li className="">Fire Safety System</li>
                                        <li className="">Paintwork</li>
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default SnaggingAndInspection;