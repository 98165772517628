import Slider from "react-slick";
import Banner from "../components/banner";
import LearnMoreBanner from "../components/banner_learn_more";
import BottumFooter from "../components/bottom_footer";
import MiddleFooter from "../components/sections/middle_footer";
import OurServices from "../components/sections/ourservices";
import Testemonial from "../components/sections/testemonial";
import TopFooter from "../components/sections/top_footer";
import ViewSomeLink from "../components/sections/view_somelink";
import Vlogs from "../components/sections/vlogs";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopNav from "../components/sections/top_nav";
import Carousel from "../components/carousel";

function HomePaage() {
    var settings = {
        arrows: false,
        fade: true,
        dots: true,
        infinite: true,
        speed: 50,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        waitForAnimate: false,
        appendDots: (dots: any) => (
            <div className='flex relative justify-center items-center px-16 pt-2.5 mt-4 w-full max-md:px-5 max-md:max-w-full'>
                <div className="flex flex-col justify-center gap-0">
                    <ul className="flex flex-row justify-center items-center p-1.5 mb-14"> {dots} </ul>

                </div>

            </div>
        ),

    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className='mt-32 md:mt-32 slider-container'>
                        <Slider {...settings} className=''>
                            <Banner sliderImage={'images/slider1.png'} desc={'Your Trusted developer Making Informed Property Decisions'} learnMoreLnk={'dave'} />
                            <Banner sliderImage={'images/slider2.png'} desc={'Reaching Greater Heights in Consulting Solution'} learnMoreLnk={'dave'} />
                            <Banner sliderImage={'images/slider3.png'} desc={'We provide Property Valuations for a wide range of purposes'} learnMoreLnk={'dave'} />
                            <Banner sliderImage={'https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/14beaa7cdb97e4e30bd2b9afd2b53f4c3dcf72b89348c52b54b6b64efca61731?apiKey=c671582bc9254df89ef0ab1440b5550b&'} desc={'Surveyed Over 100 buildings, Lands, Factories, Farms and Different  Properties'} learnMoreLnk={'dave'} />
                        </Slider>
                    </div>
                    <div className="flex justify-center">
                        <OurServices />
                    </div>

                    {/* <div className="flex justify-center my-10">
                        <Vlogs />
                    </div>

                    <div className="flex justify-center bg-stone-50">
                        <Testemonial />
                    </div> */}


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />

                </div>

            </div>
        </div>
    )

}

export default HomePaage;