/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { Link } from "react-router-dom";

type serviceProps = {
    imageSrc: string,
    description1: string
    description2: string
    lunk: string
}

function ServicesCard(props: serviceProps) {
    return (
        <div className="flex ml-3 flex-colp-20 lg:p-1 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch grow max-md:mt-10">
                <div className="flex flex-col items-center justify-center rounded-xl aspect-square bg-neutral-400 overflow-clip">
                    <img
                        loading="lazy"
                        src={props.imageSrc}
                        alt="service"
                        className="w-full  aspect-[0.95] rounded-md hover:rounded-md transform hover:scale-125 ease-in duration-200"
                    />
                </div>
                <Link to={props.lunk} className="flex gap-5 justify-between px-0.5 py-2 rounded hover: cursor-pointer">
                    <div className="text-lg font-bold leading-7 text-black text-wrap">
                        {props.description1}
                        <br />
                        {props.description2}
                    </div>
                    <div className="flex justify-center items-center my-auto bg-red-700 h-[30px] rounded-[50px] w-[30px] hover: cursor-pointer">
                        <img
                            loading="lazy"
                            src="images/services/servbut.svg"
                            className="aspect-square w-[30px]"
                        />
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default ServicesCard;