
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface AppState {
  messageUsDialog: boolean;
  toggledNavButton: string;
}

const initialState: AppState = {
  messageUsDialog: false,
toggledNavButton: "",
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMessageUsDialogStatusTo: (state, action: PayloadAction<boolean>) => {
      state.messageUsDialog = action.payload;
    },
    setToggledNavButton: (state, action: PayloadAction<string>) => {
      state.toggledNavButton = action.payload;
    },
    
  },
  
});



export const { setMessageUsDialogStatusTo, setToggledNavButton} = appSlice.actions;

export default appSlice.reducer;
