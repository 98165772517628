import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ProjectList from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

function ProjectMarketing() {
    const services = [
        { title: "Construction Consultancy" },
        { title: "Commercial Services" },
        { title: "MEP Services & Sustainability" },
        { title: "Cost to Completion Studies" },
        { title: "Project Management" },
        { title: "Construction Management" },
        { title: "Retail & Design Delivery", },
        { title: "Market Research Demand Forecasting", },
        { title: "BOQ Services" },
        { title: "Hospitality Valuation" },
        { title: "Design Management" },
        { title: "Fit-out Management" },
        { title: "Construction Project Management" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['project-marketing']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Real Estate Project Marketing Experts
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>KOKETI is Ethiopia’s leading real estate project marketing services company with expertise in direct marketing, on-site marketing, project filming, and more. We offer custom marketing solutions to real estate developers with the aim to enhance leads and conversions.</p>
                                            <p>We have a team of trained professionals in the project marketing field who understand your marketing requirements before proposing and implementing market solutions.</p>
                                            <p>We utilise the latest marketing channels, mediums, tools, and technologies to enhance the awareness of your development project in your target market. Our marketing process is customer-centric with a sole focus on creating an impact.</p>

                                        </div>
                                        <div className="text-2xl font-bold leading-10 mt-11 max-md:max-w-full">
                                            Professional Project Marketing Services in Ethiopia
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                The KOKETI Project Sales and Marketing service deploy world-class systems and approaches in real estate sales and marketing, giving developers the optimum results. Our sales and marketing team are well trained and experienced. We ensure every team member is well educated on the project they handle and how to deliver it to the client. In addition, our real estate CRM streamlines the workflow, ensuring every lead is appropriately registered, nurtured, and converted.
                                            </p>
                                            <p>
                                                Our real estate services are not a one-one business relationship with individual departments and clients. Instead, we have integrated our services to serve and guide our clients through every turn on the road to development, from market study to project sales and marketing, from investment advisory to property management, taking out the strenuous and time-consuming stages in the real estate business.
                                            </p>

                                        </div>


                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@KOKETI.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                    <div className="font-sans text-4xl font-bold">
                                        Why Choose Our Project Marketing Services?
                                    </div>
                                    <div className="text-lg">
                                        Finding the best buyers/tenants for their properties in every real estate owner’s dream. We at KOKETI understand buyers’ intuitions and know when to contact them to provide helpful information that matches their current stage while ensuring your property shines. You can trust us to market your properties in a way that passes on all buyers’ decision-making processes.
                                    </div>
                                    <ol className="list-decimal list ml-11">
                                        <li>Creating awareness about the projects in the broad market (local & international but targeted market)</li>
                                        <li>Driving traffic from various channels to the landing pages and website specially designed for project</li>
                                        <li>Conversion of driven traffic to the landing pages</li>
                                        <li>Conversion rate optimisation (CRO)</li>
                                    </ol>
                                    <div className="text-lg">To achieve the above objectives, we will deploy effective marketing, lead management and sales process strategies.</div>
                                </div>





                            </div>
                        </div>
                    </div>


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default ProjectMarketing;