import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

type FormFields = {
  firstName: string,
  lastName: string,
  companyName: string,
  mobileNumber: string,
  emailAddress: string,
  message: string

}

interface TelegramState {
  message: FormFields;
}

const initialState: TelegramState = {
  message: {
    firstName: "",
    lastName: "",
    companyName: "",
    mobileNumber: "",
    emailAddress: "",
    message: ""
},
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, () => {
        console.log("telegram message pending");
      })
    //   .addCase(
    //     incrementAsync.fulfilled,
    //     (state, action: PayloadAction<number>) => {
         
    //     }
    //   );
  },
});

export const sendMessage = createAsyncThunk(
  "counter/sendMessage",
  async (message: FormFields) => {
    const token = '6810256210:AAFunkzaMFWdcdeJdWUoz-LOZO-8T6fln3M';
    const chatId = '459660378'; // This can be a user's chat ID or group chat ID 459660378 312751458

    const url = `https://api.telegram.org/bot${token}/sendMessage`;

    
 

    try {
        const response = await axios.post(url, {
            chat_id: chatId,
            text: 'Get in touch with me first name: ' + message.firstName + ' last name: ' + message.lastName + ' mobile number: ' + message.mobileNumber + ' email address: ' + message.emailAddress + ' message: ' + message.message 
        });
        
    } catch (error) {
        console.error('Error sending message:', error);
        return 'error';
    }
    return 'success';
  }
);


export default counterSlice.reducer;