import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ImageCard from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceBox from "../../components/sections/ServicesListSection";
import ServiceListSection from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import Title from "../../components/title";

const ProjectList: React.FC = () => {
    const imageCardData = [
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/f69bde4b2b6f4f1b9d066276e6b7a6765c5d3fb5eb9f3cd73a2dd7a661071cd2?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "Misk Foundation",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/b1f3a463666916a2740344a13dd5199e15290d6b75f496e2d814d572aaa79251?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "RAKEZ",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/c667d5027efbf6e7c4e5041d4ca384c993add6e8fe8be254ec68a39a49c33cfd?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "Union Properties PJSC Dubai, UAE",
            subtitle: "Property Valuations | Strategic Consultancy",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8c666708948a09af18514a4281425d6fd26c8a12ff738b4cca13d43141262c3e?apiKey=c671582bc9254df89ef0ab1440b5550b&",
            title: "Al Ghurair Real Estate – Entire Portfolio",
            subtitle: "Property Valuations",
        },
    ];

    return (
        <div className="justify-center px-2.5 py-16 max-w-[1290px] max-md:pl-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
                    <div className="items-start self-stretch w-full px-8 pt-6 pb-20 text-4xl font-bold leading-8 text-white grow bg-sky-950 max-md:px-5 max-md:mt-10">
                        From Our <br /> Track Record
                    </div>
                </div>
                <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col px-4 grow max-md:mt-10 max-md:max-w-full">
                        <div className="max-md:max-w-full">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                {imageCardData.slice(0, 3).map((card, index) => (
                                    <div key={index} className={`flex flex-col ${index === 0 ? "w-[33%]" : "ml-5 w-[33%]"} max-md:ml-0 max-md:w-full`}>
                                        <ImageCard imageSrc={card.imageSrc} title={card.title} subtitle={card.subtitle} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-8 max-w-full text-center text-white bg-neutral-400 w-[253px]">
                            <ImageCard imageSrc={imageCardData[3].imageSrc} title={imageCardData[3].title} subtitle={imageCardData[3].subtitle} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function BuildingAndFacilityConsultancy() {
    const services = [
        { title: "Structural & MEP Testing" },
        { title: "BOQ Services" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Building Fire, Life, Health & Safety Audit" },
        { title: "Property Snagging and Inspections" },
        { title: "Survey & Audits" },
        { title: "Building Defect Investigation", },
        { title: "Measured Surveys", },
        { title: "QA/QC Inspection and Audits" },
        { title: "Building Condition Surveys & Audits – Civil, MEP, Structural & Facade" },
        { title: "Witnessing of Testing & Commissioning" },
        { title: "Energy Audits" },
        { title: "Energy Audit ASHRAE Level 1 & 2" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 bg-stone-50 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="grid justify-between grid-cols-3 gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Related Services
                    </div>
                    {services.map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>

            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"images/slider.jpg"} title={['Building & Facilities', 'Consultancy']} link={""} />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 bg-stone-50 max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col self-stretch text-lg font-light leading-7 text-black grow max-md:mt-10 max-md:max-w-full">
                                        <div className="text-4xl font-bold leading-10 max-md:max-w-full">
                                            Building & Facilities Consultancy in Ethiopia
                                        </div>
                                        <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                            <p>
                                                KOKETI’s Building & Facilities Consultancy department comprises qualified and accredited Chartered Surveyors in Ethiopia. They offer in-depth knowledge and experience in providing services across the property development industry.
                                            </p>
                                            <p>
                                                In the past decade, our property development consultants surveyed four of the tallest buildings in the Ethiopia, KOKETI has surveyed over 2000 Towers and over 100 Master Communities across the GCC region.
                                            </p>
                                            <p>
                                                Through clearly defined core services, we provide an end-to-end array of advisory, consultancy, and management services that are impressive in breadth, depth, and reach.
                                            </p>
                                            <p>
                                                We want to be your partner in creating sustainable business changes to provide long-term improvements to your organization and the built environment.
                                            </p>

                                        </div>
                                        <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                            <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                                Professional FM and Building Consultants in Ethiopia
                                            </div>
                                            <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                                <p>
                                                    Our Building Consultants in Ethiopia make sure that your facilities keep working efficiently and effectively. We provide expert advice regarding facilities management that ensure the seamless functioning of your buildings and facilities.
                                                </p>
                                                <p>
                                                    Our professional FM consultants will identify potential issues that might disturb the proper functioning of a facility. They will give you expert advice regarding the goals that you have in your mind regarding the facility.
                                                </p>
                                                <p>
                                                    We have a wide array of facilities management consultancy services that we help tailor as per the needs of our clients. We believe in working alongside our clients to understand their requirements. This enables us to come up with the best plan of action to help our clients meet their facilities management goals.
                                                </p>

                                            </div>

                                        </div>
                                        <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                            <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                                Reasons to Use Our Building & Facilities Consultancy Services
                                            </div>
                                            <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                                <p>
                                                    Our facilities management services can help streamline your facility management tasks while helping you save time and money along the way. Here are some of the most prominent reasons to use our facility management consultancy services:
                                                </p>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
                                    <div className="flex flex-col items-center self-stretch max-md:mt-10">
                                        <img
                                            loading="lazy"
                                            srcSet="images/ceo.jpg"
                                            className="max-w-full aspect-square w-[285px] rounded-full"
                                            alt=""
                                        />
                                        <div className="mt-4 text-2xl font-bold leading-7 text-center text-black">
                                            Eng. Yonas Fantahun
                                        </div>
                                        <div className="flex flex-wrap content-center self-stretch justify-center gap-5 px-16 text-lg font-medium leading-7 text-white mt-14 whitespace-nowrap max-md:px-5 max-md:mt-10">
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="tel:0930294775">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                                </svg>

                                                <div>Call</div>
                                            </a>
                                            <a className="flex items-center justify-between gap-0 px-5 py-2 rounded-lg bg-sky-950" href="mailto: info@KOKETI.com">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                                </svg>

                                                <div>Email</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">

                            <div className="flex flex-col flex-col-reverse lg:grid lg:grid-cols-2 gap-x-20">
                                <div className="">
                                    <img className="w-full h-full" src="images/services/fm1.jpg" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Enhanced Performance Efficiency</div>
                                    <div className="text-lg">
                                        Managing a facility effectively is all about optimising the day-to-day business operations while minimising overhead expenses. Our FM consultancy services will help offer you expert advice in facilities management that you can use to enhance the performance efficiency of your facility.
                                    </div>
                                </div>

                                <div className=" lg:hidden">
                                    <img className="w-full h-full" src="images/services/fm2.webp" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Less Stress for the Owners</div>
                                    <div className="text-lg">
                                        Facilities management can be stressful. Especially if you are running a huge business operation, having an in-house team for managing facilities can add to the stress. You can outsource the facilities management services to our experts, and we’ll help you make the most out of the resources that you have at your disposal.
                                    </div>
                                </div>

                                <div className="hidden lg:block">
                                    <img className="w-full h-full" src="images/services/fm2.webp" alt="" />
                                </div>
                                <div>
                                    <img className="w-full h-full" src="images/services/fm3.webp" alt="" />
                                </div>

                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Meeting Health & Safety Regulations</div>
                                    <div className="text-lg">
                                        Our facility management consultancy services will guide you through the health and safety measures that you can take to create a safer living space for people in your facility. Meeting health and safety regulations is important for running a facility smoothly.
                                    </div>
                                </div>

                                <div className=" lg:hidden">
                                    <img className="w-full h-full" src="images/services/fm4.webp" alt="" />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="pb-8 text-4xl font-bold">Reduction of Management Costs</div>
                                    <div className="flex flex-col gap-4 text-lg">
                                        <i>

                                            Facilities management consultancy services will help you learn about the current condition of your facilities, including the repairs that you need to make to keep things running smoothly
                                        </i>
                                        <i>
                                            You can act based on the reports that are given to you to keep your facilities in the best shape. This helps save money on sudden repairs because you are always staying on top of the facility condition to ensure that it keeps functioning seamlessly for you.

                                        </i>
                                    </div>
                                </div>
                                <div className="hidden lg:block">
                                    <img className="w-full h-full" src="images/services/fm4.webp" alt="" />
                                </div>

                            </div>
                            <div className="flex flex-col gap-5 max-md:flex-col max-md:gap-0">
                                <div className="text-4xl font-bold leading-10 mt-11 max-md:max-w-full">
                                    When it comes to your property, you are our priority.
                                </div>
                                <div className="mt-3 text-2xl font-bold leading-10 max-md:max-w-full">
                                    Our Professional Experience
                                </div>
                                <div className="mt-4 max-md:mr-2.5 max-md:max-w-full flex flex-col gap-10">
                                    <p>
                                        In 2015, our property development consultants surveyed the tallest tower in the world – Burj Khalifa (Dubai), along with two other towers which are in the top 10 tallest buildings in the world. KOKETI has also surveyed over 200 Towers and Communities in 2015 alone.
                                    </p>
                                    <p>
                                        In 2016, our property development consultants surveyed one of the largest portfolios in the region, comprising Yas Mall, Aldar HQ, World Trade Centre, Yas Viceroy, and 31 other buildings/communities for Aldar Properties PJSC in Abu Dhabi and Al Ain. This exposed KOKETI to the Abu Dhabi market and, at the same time, solidified our title as the leading Building Surveying services company in the region.
                                    </p>
                                    <p>
                                        Our Building Surveying team offers in-depth knowledge and experience in providing services across the construction industry in Europe, the Middle East, and North Africa.
                                    </p>
                                    <p>
                                        Our Property Development Consultants Use Precise and Data-driven Surveying Processes for Buildings & Properties in the UAE For the team in KOKETI, ensuring client satisfaction is the number one priority.
                                    </p>
                                    For this reason, we have dedicated ourselves to using advanced and data-driven surveying processes to ascertain that our clients receive top-notch services that make it possible for them to make knowledgeable decisions.

                                </div>

                            </div>
                            <ProjectList />
                        </div>
                    </div>





                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default BuildingAndFacilityConsultancy;