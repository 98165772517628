import ServiceBanner from "../../components/Servicebanner";
import BottumFooter from "../../components/bottom_footer";
import ImageCard from "../../components/sections/ProjectCard";
import ServiceProviderMessage from "../../components/sections/ServiceProviderMessage";
import ServiceListSection from "../../components/sections/ServicesListSection";
import ServiceBox from "../../components/sections/ServicesListSection";
import MyComponent from "../../components/sections/ServicesListSection";
import MiddleFooter from "../../components/sections/middle_footer";
import TopFooter from "../../components/sections/top_footer";
import TopNav from "../../components/sections/top_nav";
import ServicesList from "../../components/services_navbar";
import Title from "../../components/title";


const ProjectList: React.FC = () => {
    const imageCardData = [
        {
            imageSrc: "images/pv/pv1.jpg",
            title: "HORRA ANDERACHA COFFEE PLANTATION PLC",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv2.jpg",
            title: "ASFA PLC",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv3.jpg",
            title: "LETIZIA ASTUTI",
            subtitle: "Property Valuations | Strategic Consultancy",
        },
        {
            imageSrc: "images/pv/pv4.jpg",
            title: "GARALASAR TRADING PLC",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv5.jpg",
            title: "EFOYETA BEKOLFE PLC",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv6.jpg",
            title: "BEN MORISON",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv7.jpg",
            title: "FRANKUN ET AUTOMOTIVE ENGINEERING PLC",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv8.jpg",
            title: "Al Ghurair Real Estate – Entire Portfolio",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv9.jpg",
            title: "FRANKUN ET AUTOMOTIVE ENGINEERING PLC",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv10.jpg",
            title: "Aschalew Berhanu Dhakabora Engineering",
            subtitle: "Property Valuations",
        },
        {
            imageSrc: "images/pv/pv11.jpg",
            title: "Wow Burger Production PLC",
            subtitle: "Property Valuations",
        },

    ];

    return (
        <div className="justify-center px-2.5 py-16 max-w-[1290px] max-md:pl-5 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
                    <div className="items-start self-stretch w-full px-8 pt-6 pb-20 text-4xl font-bold leading-8 text-white grow bg-sky-950 max-md:px-5 max-md:mt-10">
                        From Our <br /> Track Record
                    </div>
                </div>
                <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col px-4 grow max-md:mt-10 max-md:max-w-full">
                        <div className="max-md:max-w-full">
                            <div className="grid grid-cols-3 gap-5 ">
                                {imageCardData.map((card, index) => (
                                    <div key={index} className={`flex flex-col max-md:ml-0 max-md:w-full`}>
                                        <ImageCard imageSrc={card.imageSrc} title={card.title} subtitle={card.subtitle} />
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};



function PropertyEvaluation() {
    const services = [
        { title: "Residential Property Valuation" },
        { title: "Commercial Property Valuation", },
        { title: "Healthcare & Education Valuation", },
        { title: "Hospitality Valuation" },
        { title: "Industrial & Logistic Valuation" },
        { title: "Leisure & Entertainment Valuation" },
        { title: "Retail Valuation" },
    ];

    const ServiceListSection: React.FC = () => {
        return (
            <section className="flex flex-col px-3 mt-14 mb-3.5 max-w-full text-lg leading-6 w-[1218px] max-md:mt-10">
                <div className="flex justify-between gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="justify-center items-center self-start px-6 py-2.5 text-3xl font-semibold leading-10 text-center whitespace-nowrap bg-white rounded-xl shadow-2xl max-md:px-5">
                        Services
                    </div>
                    {services.slice(0, 2).map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>
                <div className="flex justify-between gap-5 mt-8 max-md:flex- max-md:max-w-full">
                    {services.slice(2, 5).map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>
                <div className="flex gap-5 justify-between mt-8 max-w-full w-[783px] max-md:flex-wrap">
                    {services.slice(5).map((service, index) => (
                        <ServiceBox key={index} title={service.title} />
                    ))}
                </div>
            </section>
        );
    };

    return (
        <div className="flex flex-col justify-center bg-stone-50 max-md:max-w-full">
            <div className="flex flex-col w-full bg-white max-md:max-w-full">
                <div className="flex flex-col justify-center w-full bg-white max-md:max-w-full">

                    <div className="relative ">
                        <div className="fixed top-0 left-0 right-0 z-20">
                            <TopNav />
                        </div>

                    </div>
                    <div className="mt-40">
                        <ServiceBanner image={"https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/94e7c91f0dc4744c442d2e4101ece159584fd2c95da2f963eab48e9c7b3d605a?apiKey=c671582bc9254df89ef0ab1440b5550b&"} title={['Property', 'Valuations']} link={""} />
                    </div>
                    <div className="">
                        <ServiceProviderMessage />
                    </div>
                    <div className="flex items-center self-stretch justify-center w-full px-16 font-sans max-md:px-5 max-md:max-w-full">
                        <div className="justify-center px-6 py-16 max-w-full w-[1290px] max-md:px-5">
                            <div className="flex flex-col gap-11 max-md:flex-col max-md:gap-0">
                                <div className="flex flex-col text-center gap-11">
                                    <Title title={"Why Choose Our Property Valuation Services?"} />
                                    <p className="text-left ">
                                        KOKETI is an RICS-regulated real estate firm. We have a dedicated team
                                        of RICS- and TAQEEM-certified chartered surveyors who are highly competent in
                                        providing you with fair and accurate valuations across all real estate sectors
                                        throughout the MENA region. Here are some of the reasons why our property valuation
                                        services stand out:
                                    </p>
                                    <ul className="pl-16 text-left list-disc">
                                        <li>RERA-regulated property valuation company
                                        </li>
                                        <li>8 years of experience in property valuation
                                        </li>
                                        <li> ISO-certified
                                        </li>
                                        <li>100+ projects</li>
                                    </ul>

                                    <p className="text-left">As one of the leading property valuation companies in Ethiopia, we ensure that all our reports are prepared in accordance with the standards:</p>

                                    <ul className="pl-16 text-left list-disc">
                                        <li>
                                            The Royal Institution of Chartered Surveyors (RICS)
                                        </li>
                                        <li>
                                            The International Association of Certified Valuation Specialists (IACVA)
                                        </li>
                                        <li>
                                            Attributed and recognized by Ethiopian government authorities
                                        </li>

                                    </ul>
                                </div>
                                <div className="flex flex-col gap-10">
                                    <Title title={"Sector-specialised Property Valuation"} />
                                    <p>With extensive experience and an outstanding reputation
                                        amongst leading property valuation firms in Ethiopia, our
                                        team has expertise in a range of sectors.</p>
                                    <div className="hidden lg:block">
                                        <ServiceListSection />
                                    </div>

                                    <div className="flex flex-col items-center justify-center gap-7 lg:hidden">
                                        <div className="w-full px-5 py-8 rounded-lg shadow-lg">
                                            <div className="flex justify-between ">
                                                <div className="text-2xl font-bold">Service</div>

                                            </div>
                                        </div>

                                        {
                                            services.map((service, index) => (
                                                <div className="w-full px-5 py-8 rounded-lg shadow-2xl">
                                                    <div className="flex justify-center">
                                                        <div>{service.title}</div>
                                                        <svg className="shrink-0 my-auto aspect-square w-[18px]" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="flex flex-col gap-6 bg-white mt-11 ">
                                    <div className="text-3xl font-bold">Our Services Cover</div>
                                    <div className="text-lg">We provide Property Valuations for a wide range of purposes such as:</div>
                                    <ul className="grid grid-cols-1 text-lg list-disc lg:grid-cols-2 list pl-11">
                                        <li className="list-disc">Purchase or Sale</li>
                                        <li className="">Transfer of Wealth</li>
                                        <li className="">Equity & Debt Financing</li>
                                        <li className="">Disputes & Litigation</li>
                                        <li className="">Mergers & Acquisitions</li>
                                        <li className="">REITS & Investment</li>
                                        <li className="">Financial / Annual Reporting</li>
                                        <li className="">REITS & Investment</li>
                                        <li className="">Portfolio Valuations</li>
                                        <li className="">Insurance Valuations</li>
                                        <li className="">Lease Renegotiations</li>
                                        <li className="">Litigation & Compensation</li>
                                    </ul>
                                    <div className="text-lg">
                                        Consult with our experts who can access our extensive information available across asset classes, to deliver property valuation that meet your specific needs.
                                    </div>
                                </div>
                                <div className="flex flex-col gap-6 font-sans bg-white mt-11 even:mb-11">
                                    <div className="font-sans text-4xl font-bold">
                                        Reasons to Hire a Property Valuation Company in Ethiopia
                                    </div>
                                    <div className="text-lg">
                                        Independent property valuation may come off as an unnecessary expense. But this is far from the truth. Property valuation is not only necessary, but it can also prove to be quite important for you in maximising the return on your investment when buying or selling the property.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Benefits for Buyers
                                    </div>
                                    <div className="text-lg">
                                        Property valuation helps the buyer make an informed decision before buying a property. A property valuation involves a thorough inspection of the real estate. The in-depth inspection often leads to finding potential points that might lead to getting a better deal on the property. KOKETI’s property consultant in Ethiopia services are ideal for buyers who want to buy a property that gives them the best value for their money.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Benefits for Sellers
                                    </div>
                                    <div className="text-lg">
                                        Sellers need to have a clear idea about the estimated value of their property as per the market trends. Setting a value that is way higher or way lower than the market trends can lead to a delay in making the sale. KOKETI offers professional property valuation services that help sellers get a market-accurate estimate for the worth of their property. You can use the information provided by KOKETI to maximise your return on investment.
                                    </div>
                                    <div className="text-2xl font-bold">
                                        Trusted Real Estate Valuation in Ethiopia
                                    </div>
                                    <div className="text-lg">
                                        Being an industry leader in the Ethiopia property valuation market, we value honesty and transparency. This is what inspires us to go out of our way to offer you the best real estate valuation services that you can use to make the right decisions with your property transaction process.


                                    </div>
                                    <div className="text-lg">Get in touch with KOKETI right now to get an accurate property valuation for your commercial or residential property.</div>
                                </div>
                                <ProjectList />



                            </div>
                        </div>
                    </div>


                    <TopFooter />
                    <MiddleFooter />
                    <BottumFooter />


                </div>

            </div>
        </div>
    )

}

export default PropertyEvaluation;