import { useState } from "react";
import TopNav from "../components/sections/top_nav";
import BottumFooter from "../components/bottom_footer";
import MiddleFooter from "../components/sections/middle_footer";
import TopFooter from "../components/sections/top_footer";

export default function AboutUsPage() {

    const [activeButtonv, setActiveButtonv] = useState<string>(''); // State to track the active button

    const togglev = (buttonName: string) => {
        console.log('sssssssssssssssssssssssssss')
        setActiveButtonv(activeButtonv === buttonName ? '' : buttonName);
    };

    return (
        <div className="flex flex-col items-center font-sans ">
            <div className="w-full">
                <TopNav />
            </div>
            <div className="relative mb-16">
                <img className="" src="images/abg.png" alt="banner" />
                <div className="absolute font-sans text-6xl font-black bottom-36 left-80">
                    About Us
                </div>
            </div>
            <div className="flex flex-col justify-center w-8/12 gap-7">
                <div className="text-4xl font-bold">
                    Your trusted partner in making informed property decisions.
                </div>
                <div className="flex flex-col gap-4 mb-16 text-lg ">
                    <i>
                        KOKETI Consulting is an Ethiopian based
                        independent private multi-sectored
                        consultancy company offering a broad
                        range of service in Asset and/or Business
                        Valuation. KOKETI is a consultancy
                        company that has built a strong
                        reputation for delivering a quality service
                        on time at a competitive price. We pride
                        ourselves on being skilled and
                        experienced in all aspects of the services
                        we give and this, combined with our
                        extensive knowledge of the market, has
                        established us as major players in this
                        industry. Our commitment to innovation
                        and excellent invariably results in a
                        successful consultancy service for our
                        clients. In general, we understand and
                        promote the idea of working as
                        partnerships with our clients to ensure
                        their goals are met.
                    </i>
                    <i>
                        KOKETI Consulting is a market leader in
                        Asset and Business consultancy based in
                        Ethiopia. Backed with qualified experts
                        with over 100 years of cumulative
                        experience, KOKETI Consulting provides
                        professional property expertise cutting
                        through various regions, including the
                        East Africa, North Africa, Asia & Europe.
                        We help our clients with analytical and
                        research-based advice to make informed
                        property decisions, reduce risk and
                        improve overall success. KOKETI
                        Consulting offers a full range of property
                        consultancy services in accordance with
                        International Valuation Standards across
                        different industries.
                    </i>
                    <i>
                        In the last 8 years, KOKETI has
                        accomplished the valuation of 20 billion
                        Ethiopian Birr worth of properties, has
                        surveyed over 100 buildings, Lands,
                        Factories and other prominent local and
                        international faculties.
                    </i>
                </div>
            </div>
            <div className="flex flex-col items-center w-full py-20 font-sans bg-stone-50" >

                <div className="grid grid-cols-1 gap-16 ml-9 lg:w-8/12 lg:grid-cols-5">
                    <div className="flex flex-col col-span-3 divide-y-2 divide-gray-300 divide-dashe">
                        <div className="mb-3 font-serif text-5xl font-black">
                            Why we are different?
                        </div>
                        <div className="flex flex-col pt-10 gap-y-4">
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> <i className="font-bold">8 years</i> of excellence</i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> <i className="font-bold">100</i>  completed assignments</i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> <i className="font-bold">200B+ Birr</i>  worth of projects valued</i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> Major banks accept our reports in the Ethiopia & East African region</i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> Recognized by Ethiopian big & Best auditing firms, Ethiopia Courts</i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> Top & Best Appraisal firm specializing in Asset & Business Valuation </i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i> Surveyed Over 100 buildings, Lands, Factories, Farms and Different
                                    Properties.</i>
                            </div>
                            <div className="flex items-center justify-start gap-3 text-lg">
                                <svg className="w-5 h-5 " data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                                <i>Employing the Best Minds in the Industrys</i>
                            </div>

                        </div>
                    </div>
                    <div className="w-[429px] h-[496px] col-span-2">
                        <img src="images/abts.jpg" alt="abt" />
                    </div>

                </div>


            </div>
            <div className="flex flex-col items-center w-full py-20 font-sans bg-stone-50" >

                <div className="grid w-8/12 grid-cols-1 lg: lg:grid-cols-4 lg:gap-16">
                    <div className="col-span-1 rounded-lg w-80 h-4/4 overflow-clip">
                        <img src="images/valu.webp" alt="abt" />
                    </div>
                    <div className="flex flex-col col-span-3 px-6 divide-y-2 divide-gray-300 divide-dashe">
                        <div className="pb-3 font-sans text-4xl font-black">
                            Our Values & Ethics
                        </div>
                        <div className="pt-3">
                            <div className="flex flex-col gap-2 p-5">
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v1')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Exemplary Service Standards</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v1' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Commit to delivering exceptional services, perpetually refining to surpass client expectations.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v2')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Resolute Accountability</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v2' ? 'pb-2 ' : 'hidden pb-3'}>
                                        Embrace ownership, transparently communicate results, and instill a culture of responsibility.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v3')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Respectful Collaboration</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v3' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Value diversity, foster inclusivity, and nurture a collaborative environment where every contribution is honored.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v4')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Client-Centric Focus</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v4' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Prioritize clients through active listening, consistently delivering value, and building enduring relationships.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v5')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Proactive Responsibility</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v5' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Empower team members to take initiative, make decisive contributions, and drive the company’s success.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v6')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Unconditional Dependability</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v6' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Demonstrate reliability and consistency, ensuring stability and trust in relationships with clients and stakeholders.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v7')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Integrity and Transparency</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v7' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Conduct business with the utmost honesty and integrity, building trust through transparency and ethical conduct.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v8')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Trustworthy Commitment</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v8' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Uphold trust through ethical behavior, maintaining confidentiality, and honoring commitments.
                                    </div>
                                </div>
                                <div className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " onClick={() => togglev('v9')}>
                                    <div className="flex justify-between mb-3 font-bold ">
                                        <div>Pursuit of Professional Excellence</div>
                                        <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                        </svg>
                                    </div>
                                    <div className={activeButtonv === 'v9' ? 'pb-3 ' : 'hidden pb-3'}>
                                        Strive for excellence, investing in the professional growth of team members to ensure expertise and continual advancement.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>


            </div>
            <div className="relative flex-row justify-between">
                <img className="hidden lg:block" src="images/mis.png" alt="banner" />
                <div className="lg:absolute lg:w-5/12 mb-6 p-10 bg-black rounded-lg text-white bg-opacity-40 lg:top-[90px] lg:left-80">
                    <div className="mb-5 text-3xl font-medium o">Our Vission</div>
                    <div className="text-pretty">
                        To provide Valuation services and advice to individuals, private companies, government organizations,
                        global corporations, financial institutions, developers, owners’ associations, and asset managers at the
                        highest standard.
                    </div>
                </div>
                <div className="lg:absolute lg:w-5/12 p-10 bg-black rounded-lg text-white opacity-40 lg:bottom-[90px] lg:left-[840px] ">
                    <div className="mb-5 text-3xl font-medium ">Our Mission</div>
                    <div className="text-pretty ">
                        To modernize the real estate experience by inspiring a culture of innovation, collaboration and discovery
                    </div>
                </div>
            </div>
            <TopFooter />
            <MiddleFooter />
            <BottumFooter />

        </div>
    )

}