import * as React from "react";
import ServiceNavBar from "./ServiceNavButton";
import PropIcon from "./serviceIcons/properteval";

function ServicesList() {



    return (
        <div className="z-10 grid grid-cols-4 pb-14 bg-opacity-95 p-11 gap-11 bg-slate-100 h-2/4">
            <div className="flex flex-col">
                <div className="text-4xl font-bold leading-10 text-black mb-11">
                    Our Services
                </div>
                <div className="pb-4 text-lg font-normal align-baseline text-slate-700">
                    Experience our expertise in real estate investment advisory,
                    project management, and building consultancy services.
                    Elevate your ventures with our comprehensive support.
                </div>
                <div className=" w-[427px] h-[245px] rounded-lg overflow-clip absolute bottom-1 absolute">
                    <img width={427} height={245} src="images/slider.jpg" alt="bann" />
                    <div className="absolute inline-flex items-center justify-between px-5 py-2 ml-6 font-normal text-white bg-red-700 rounded-md bottom-10">
                        Enquire Now
                        <svg className="w-5 h-5 ml-2 font-bold" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"></path>
                        </svg>
                    </div>
                    <div className="absolute ml-6 text-3xl font-bold text-gray-700 top-4">
                        Get Free Qoute
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <ServiceNavBar icon={PropIcon} heading={"Property Valuation"} link={'/property-evaluation'} />
                <ServiceNavBar icon={PropIcon} heading={"Project Managment & Cost Consultancy"} link={'/project-managment-and-cost-consultancy'} />
                <ServiceNavBar icon={PropIcon} heading={"Project Marketing"} link={'/project-marketing'} />
                <ServiceNavBar icon={PropIcon} heading={"Snagging & Inspection"} link={'/inspection-snagging'} />
            </div>
            <div className="flex flex-col">
                <ServiceNavBar icon={PropIcon} heading={"Property Strategic Consultancy"} link={'/property-strategic-consultancy'} />
                <ServiceNavBar icon={PropIcon} heading={"Sales & Leasing Brokerage"} link={'/sales-and-leasing-brokerage'} />
                <ServiceNavBar icon={PropIcon} heading={"Property Investment Advisory"} link={'/property-investment-advisory'} />
                <ServiceNavBar icon={PropIcon} heading={"Sectors We Serve"} link={'/sectors-we-serve'} />
            </div>
            <div className="flex flex-col ">
                <ServiceNavBar icon={PropIcon} heading={"Building & Facilities Consultancy"} link={'/building-and-facilities-consultancy'} />
                <ServiceNavBar icon={PropIcon} heading={"Property Management"} link={'/property-management'} />
                <ServiceNavBar icon={PropIcon} heading={"Industrial Consultancy"} link={'/industrial-consultancy'} />
            </div>


        </div>
    );
}

export default ServicesList;