import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { sendMessage } from "../../states/telegram/telegram";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../states/store";

type FormFields = {
  firstName: string,
  lastName: string,
  companyName: string,
  mobileNumber: string,
  emailAddress: string,
  message: string

}

function TopFooter() {

  const firstName = React.useRef(null);
  const dispatch = useDispatch<AppDispatch>();

  const { register, handleSubmit, reset } = useForm<FormFields>();

  const onSubmit: SubmitHandler<FormFields> = (data: FormFields) => {
    console.log(data);
    if (data.mobileNumber || data.emailAddress) {

      dispatch(sendMessage(data));
      alert("We will contact you shortly.")
      reset();

    }
  }
  return (
    <div className="flex flex-col w-full px-16 pt-8 mt-16 bg-sky-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="justify-center pb-3.5 mx-10 max-md:mr-2.5 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[67%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch grow max-md:mt-10 max-md:max-w-full">
              <div className="justify-center pb-8 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow self-stretch pt-5 pb-2.5 text-base text-white max-md:mt-8">
                      <div className="text-xl font-bold leading-6">Meet Us</div>
                      <div className="mt-5 font-light leading-6 text-stone-300">
                        Office 502, Duki Building, Gabon
                        <br />
                        Street, Meskel flower, Ethiopia, Addis Ababa.
                      </div>
                      <div className="mt-4 leading-[167%]">Locate us</div>
                    </div>
                  </div>
                  <div className="flex flex-col w-6/12 ml-5 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col self-stretch pt-5 pb-4 text-base font-bold leading-6 max-md:mt-8">
                      <div className="text-xl leading-6 text-white">
                        Get in touch
                      </div>
                      <div className="mt-5 font-light text-zinc-300">
                        Feel free to call and discuss about your property
                      </div>
                      <div className="text-white">+251 93 029 4775</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center ">
                <i className="mb-5 text-2xl text-white">
                  Accredited by Ethiopian Government
                </i>
                <img
                  loading="lazy"
                  srcSet="images/dada.png"
                  className="z-10 mt-0 w-[600px] aspect-[4.17] max-md:max-w-full place-self-center"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
            <form className="flex flex-col self-stretch py-5 font-light text-white max-md:mt-10" onSubmit={handleSubmit(onSubmit)}>
              <div className="text-xl font-bold leading-6">
                Request A Callback
              </div>
              <div className="flex items-start justify-between gap-4 pt-px pb-5 pl-px mt-5 text-lg text-neutral-400">
                <input {...register("firstName")} className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="First Name" />
                <input {...register("lastName")} className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Last Name" />

              </div>
              <div className="flex items-start justify-between gap-4 pb-5 pl-px text-lg text-neutral-400">
                <input {...register("mobileNumber")} className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Mobile Number" />

                <input {...register("emailAddress")} className="items-start justify-center w-full px-4 py-3 border border-gray-200 border-solid rounded-md border-opacity-80 max-md:pr-5" placeholder="Email Address" />

              </div>

              <button type="submit" className="justify-center self-start px-5 py-2.5 mt-6 text-base font-medium leading-6 text-center whitespace-nowrap bg-red-700 rounded-lg border border-red-700 border-solid max-md:px-5 hover:cursor-pointer">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFooter;
