import { Transition, Dialog } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { AppDispatch, RootState } from "../states/store";
import { setMessageUsDialogStatusTo, setToggledNavButton } from "../states/app/appSlice";


const MobileMenu = () => {


    const dispatch = useDispatch<AppDispatch>();

    const [activeButton, setActiveButton] = useState<string>('');


    const toggleButton = (buttonName: string) => {
        setActiveButton(activeButton === buttonName ? '' : buttonName);
    };



    return (
        <div className="flex flex-col bg-stone-100 ">
            <div className="flex flex-col content-center justify-center gap-5 py-6 text-xl leading-6 text-black p-44 px-7">
                <Link to={'/'} className="flex justify-between gap-1 pr-5 whitespace-nowrap hover:text-red-700 hover:cursor-pointer hover:font-bold" onClick={
                    () => dispatch(setToggledNavButton(''))
                }>
                    <div>
                        Home
                    </div>

                </Link>
                <div className="w-full">

                    <div className="flex flex-row justify-between gap-1 pr-5 whitespace-nowrap hover:text-red-700 hover:cursor-pointer hover:font-bold" onClick={() => toggleButton('service')}>
                        <div>Services</div>
                        <div>
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="angle-down"><path fill="#FF1414" d="M16.9,9.2c-0.4-0.4-1-0.4-1.4,0L12,12.7L8.5,9.2c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l4.2-4.2C17.3,10.2,17.3,9.6,16.9,9.2z"></path></svg>
                        </div>
                    </div>
                    <div className={activeButton === 'service' ? '' : 'hidden'}>
                        <div className="grid grid-cols-2 gap-3 pr-5">
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-evaluation"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Valuations</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>

                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/project-managment-and-cost-consultancy"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            } >
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Project Management & Cost Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/project-marketing"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Project Marketing</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/inspection-snagging"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Snagging & Inspection</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-strategic-consultancy"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Strategic Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/sales-and-leasing-brokerage"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Sales & Leasing Brokerage</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-investment-advisory"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Investment Advisory</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={""} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Sectors We Serve</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/building-and-facilities-consultancy"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Building & Facilities Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/property-management"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Property Management</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                            <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/industrial-consultancy"} onClick={
                                () => dispatch(setToggledNavButton(''))
                            }>
                                <div className="flex justify-between mb-3 text-base font-normal ">
                                    <div>Industrial Consultancy</div>
                                    <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
                <button className="flex justify-between gap-1 pr-5 hover:text-red-700 hover:cursor-pointer hover:font-bold">
                    <div>Insight & Trends</div>
                    <div>
                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="angle-down"><path fill="#FF1414" d="M16.9,9.2c-0.4-0.4-1-0.4-1.4,0L12,12.7L8.5,9.2c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l4.2-4.2C17.3,10.2,17.3,9.6,16.9,9.2z"></path></svg>
                    </div>
                </button>
                <button className="flex justify-between gap-1 pr-5 hover:text-red-700 hover:cursor-pointer hover:font-bold" onClick={() => toggleButton('aboutus')}>
                    <div>About Us</div>
                    <div>
                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="angle-down"><path fill="#FF1414" d="M16.9,9.2c-0.4-0.4-1-0.4-1.4,0L12,12.7L8.5,9.2c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l4.2-4.2C17.3,10.2,17.3,9.6,16.9,9.2z"></path></svg>
                    </div>
                </button>
                <div className={activeButton === 'aboutus' ? '' : 'hidden'}>
                    <div className="grid grid-cols-2 gap-3 pr-5">
                        <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/about-us"} onClick={
                            () => dispatch(setToggledNavButton(''))
                        }>
                            <div className="flex justify-between mb-3 text-base font-normal ">
                                <div>About Us</div>
                                <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                            </div>

                        </Link>
                        <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/offices"} onClick={
                            () => dispatch(setToggledNavButton(''))
                        }>
                            <div className="flex justify-between mb-3 text-base font-normal ">
                                <div>Offices</div>
                                <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                            </div>
                        </Link>
                        <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={"/our-leaders"} onClick={
                            () => dispatch(setToggledNavButton(''))
                        }>
                            <div className="flex justify-between mb-3 text-base font-normal ">
                                <div>Leadership</div>
                                <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                            </div>
                        </Link>
                        <Link className="p-2 bg-white rounded-lg shadow-lg hover:cursor-pointer " to={""} onClick={
                            () => dispatch(setToggledNavButton(''))
                        }>
                            <div className="flex justify-between mb-3 text-base font-normal ">
                                <div>Careers</div>
                                <svg className="w-6 h-6 text-red-700 hover:text-slate-900" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                                </svg>
                            </div>
                        </Link>

                    </div>
                </div>
            </div><button className="justify-center px-5 py-2 my-auto text-lg font-medium leading-7 text-white bg-red-700 rounded-md" onClick={() => dispatch(setMessageUsDialogStatusTo(true))}>
                Enquire Now
            </button>

        </div>
    )
}


export default MobileMenu;